:root {
    --white-color: #fff;
    --white-color-2: #dddddd;
    --black-color: #000000;
    --darkGrey-color: #1b1b1b;
    --grey-color: #494a50;
    --violet-color: #ae80f0;
    --rose-color: #b87af7;
    --green-color: #b1f9e0;
    --error-color: #b95959;
    --lightGrey-color: #a1a1a1;
    --grey-color-2: #c1c1c1;
    --grey-color-3: #494a51;

    --font-size-20: calc((20 / 16) * 1rem);
    --font-size-60: calc((60 / 16) * 1rem);
    --font-size-48: calc((48 / 16) * 1rem);
    --font-size-32: calc((32 / 16) * 1rem);
    --font-size-30: calc((30 / 16) * 1rem);
    --font-size-24: calc((24 / 16) * 1rem);
    --font-size-20: calc((20 / 16) * 1rem);
    --font-size-18: calc((18 / 16) * 1rem);
    --font-size-16: 1rem;
    --font-size-14: calc((14 / 16) * 1rem);
    --font-size-12: calc((12 / 16) * 1rem);
    --font-size-10: calc((10 / 16) * 1rem);
    --font-size-8: calc((8 / 16) * 1rem);
}
