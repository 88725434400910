.spinner {
    width: 20px;
    height: 20px;
    background-color: var(--black-color);
    border-radius: 50%;
    border: 2px solid var(--rose-color);
    border-bottom-color: transparent;
    position: relative;
    animation: spinnerAnim 1s linear infinite;
}

@keyframes spinnerAnim {
    to {
        transform: rotate(1turn);
    }
}
