@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
.rkl-loading-spinner_spinnerWrapper__1iI6x {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
}
.rkl-loading-spinner_spinner__2kcpP {
    border: 4px solid var(--black-color);
    border-top: 4px solid var(--white-color);
    border-radius: 50%;
    -webkit-animation: rkl-loading-spinner_spin__qoazs 1s linear infinite;
            animation: rkl-loading-spinner_spin__qoazs 1s linear infinite;
    height: 100%;
    width: 100%;
}

@-webkit-keyframes rkl-loading-spinner_spin__qoazs {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rkl-loading-spinner_spin__qoazs {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.rkl-button_button___Hfy6 {
    padding: 22px 52px 22px 52px;
    border-radius: 60px;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: 700;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
}

.rkl-button_square__2XJHp {
    width: 68px;
    height: 58px;
    border-radius: 12px;
    white-space: normal;
    padding: 18px 14px;
    font-size: var(--font-size-12);
}

.rkl-button_square__2XJHp span {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.rkl-button_small__Gjoqm {
    width: 68px;
    height: 34px;
    border-radius: 45px;
    padding: 8px 11px;
    font-size: var(--font-size-14);
}

.rkl-button_lgDesktop__1bc1O {
    width: 168px;
    height: 68px;
    padding: 18px 32px;
    border-radius: 40px;
    font-size: var(--font-size-20);
}

.rkl-button_medDesktop__iafX3 {
    width: 145px;
    height: 68px;
    padding: 21px 40px;
    border-radius: 40px;
    font-size: var(--font-size-20);
}

.rkl-button_mobile__2B9mW {
    width: 139px;
    height: 64px;
    font-size: var(--font-size-20);
    padding: 20px 37px;
}

.rkl-button_lgMobile__3ipxl {
    width: 171px;
    height: 64px;
    padding: 20px 53px;
    font-size: var(--font-size-20);
}

.rkl-button_twitter__ySLBl {
    width: 171px;
    height: 68px;
    padding: 21px 40px;
    font-size: var(--font-size-20);
}

.rkl-button_twitterButtonIcon__W_dF3{
    margin-left: 10px;
    display: -webkit-flex;
    display: flex;
}

.rkl-button_squared__3EP4Q {
    border-radius: 12px;
}

.rkl-button_violetBG__m3EoU {
    background-color: var(--violet-color);
    color: var(--black-color);
}

.rkl-button_whiteBG__33yv5 {
    background-color: var(--white-color);
}

.rkl-button_whiteBG__33yv5:hover, 
.rkl-button_whiteBG__33yv5:active {
    background-color: var(--lightGrey-color) !important;
}

.rkl-button_greenBG__3LCKD {
    background-color: var(--green-color);
    color: var(--black-color);
}

.rkl-button_borderOnlyViolet__Dy7BV {
    background-color: transparent;
    color: var(--violet-color);
    border: 1px solid var(--violet-color);
}

.rkl-button_loading__29o2j {
    background-color: var(--white-color);
    color: var(--white-color-2);
}

.rkl-button_borderOnlyWhite__3rK5n {
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color);
}

.rkl-button_button___Hfy6:hover {
    background-color: var(--white-color);
    color: var(--black-color);
}

.rkl-button_button___Hfy6:active {
    background-color: var(--white-color);
    color: var(--black-color);
    margin-top: 2px;
    margin-bottom: -2px;
}

.rkl-button_greenBG__3LCKD:hover {
    border: 1px solid var(--white-color);
}

.rkl-button_button___Hfy6:disabled {
    color: black;
    background-color: var(--grey-color);
}

.rkl-button_button___Hfy6:hover:disabled {
    cursor: not-allowed;
}

.rkl-button_button___Hfy6:active:disabled {
    margin-top: 0;
}

.rkl-button_btnSpan__aUDgU {
    display: -webkit-flex;
    display: flex;
    white-space: nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.rkl-button_decorator__99pN- {
    padding: 3px 7px;
    border-radius: 40px;
    background-color: var(--black-color);
}

.rkl-button_button___Hfy6:hover:enabled .rkl-button_decorator__99pN- {
    color: var(--white-color);
}

.rkl-button_fontColorViolet__3pq95 {
    color: var(--violet-color);
}

.rkl-button_fontColorBlack__3c8cN {
    color: var(--black-color);
}

.rkl-button_fontColorGreen__2asBQ {
    color: var(--green-color);
}

.error-boundary_overlay__Ib13g {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.error-boundary_modalDialog__2iDyN {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 800px;
    border-radius: 23px;
    height: auto;
    text-align: center;
    background: var(--black-color);
    margin: 0 12px;
    border: 1px solid var(--grey-color);
    position: relative;
    color: var(--white-color);
}

.error-boundary_modalDialogContent__1h9Zh {
    padding: 32px 24px;
}

.error-boundary_header__Jjh4l {
    margin-bottom: 8px;
}

.error-boundary_text__2dKQp {
    font-size: var(--xregular-font-size);
    font-weight: bold;
    margin-bottom: 12px;
}

.error-boundary_errorContent__2aRQ0 {
    background: #1e1e1e;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 23px;
    font-size: 14px;
}

.error-boundary_buttons__2Kpw2 button {
    margin: 0 5px;
}

.button_button__2-1iQ {
    position: relative;
    display: inline-block;
    line-height: 1;
    outline: none;
    border: 0;
    cursor: pointer;
}

.button_sizeNormal__D2k73 {
    padding: 24px;
    border-radius: 40px;
}

.button_sizeSmall__1-794 {
    padding: 16px;
    border-radius: 30px;
    min-width: 80px;
    text-align: center;
}

.button_sizeXSmall__9JJOP {
    padding: 8px 16px;
    border-radius: 16px;
}

.button_sizeXSmall__9JJOP {
    padding: 8px 16px;
    border-radius: 16px;
}

.button_sizeXSmall__9JJOP {
    padding: 8px 16px;
    border-radius: 16px;
}

.button_sizeMedium__1NPyp {
    padding: 16px;
    border-radius: 30px;
}

.button_disabled__2slTz {
    opacity: 0.8;
    background-color: #9d99a1;
    cursor: default;
}

@media screen and (max-height: 500px) {
    .button_sizeNormal__D2k73 {
        padding: 12px 16px;
        border-radius: 30px;
    }
}

/* FONT */

.styles_fontSizeXLarge__4mUyj {
    font-size: 60px;
    font-weight: 500;
}

.styles_fontSizeExtraLarge__3Y182 {
    font-size: 40px;
    font-weight: 600;
}

.styles_fontSizeLarge__1xVNr {
    font-size: 32px;
    font-weight: 400;
}

.styles_fontSizeXMedium__3szor {
    font-size: 24px;
    font-weight: 400;
}

.styles_fontSizeMedium__QzUDE {
    font-size: 20px;
    font-weight: bold;
}

.styles_fontSizeXSmall__1rVWi {
    font-size: 16px;
    font-weight: 500;
}

.styles_fontSizeMediumNormal__3vXrh {
    font-size: 20px;
    font-weight: 400;
}

.styles_fontSizeNormal__1edEv {
    font-size: 16px;
    font-weight: 400;
}

.styles_lineHeightNormal__Ywb5z {
    line-height: 1.6;
}

.styles_fontColorWhite__1Ajd- {
    color: #fefefe;
}

.styles_fontColorBlack__pF-4F {
    color: #000000;
}

.styles_fontColorGray__12tQd {
    color: #a6a6a6;
}

.styles_fontColorRose__14zuT {
    color: #b87af7;
}

.styles_fontColorLightGray__2KwQA {
    color: #c3c3c3;
}

.styles_fontColorYellow__2CzQ3 {
    color: #feda5e;
}

/* BG */

.styles_bgColorBlack__1FEds {
    background-color: #000000;
}

.styles_bgColorWhite__StWBb {
    background-color: #ffffff;
}

.styles_bgColorTransparent__pTRn_ {
    background-color: transparent;
}

.styles_bgColorRose__-ACMf {
    background-color: #b87af7;
}

.styles_borderColorBlack__1nxPE {
    border: 1px solid #000000;
}

.styles_textAlignLeft__C6j-2 {
    text-align: left;
}

.styles_page__SzPuP {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_staticPage__3AHRJ {
    width: 100%;
    margin-top: 94px;
    min-height: calc(100vh - 94px);
    height: 100%;
    background: #000000;
}

.styles_staticPageInner__3IYcF {
    max-width: 800px;
    padding: 24px 24px 48px;
    margin: 0 auto;
}

.styles_staticPageInnerWide__3Mwk- {
    width: 100%;
    padding: 24px 32px 48px;
    margin: 0 auto;
}

.styles_pageTitle__3FQGy {
    padding: 32px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.styles_contentCenter___Ps8Q {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_roundBlock__2H9c6 {
    position: relative;
    background: #000000;
    border: 1px solid #494a51;
    padding: 24px 0;
    border-radius: 12px;
}

.styles_roundBlockDelimiter__3nN0u {
    width: 100%;
    height: 1px;
    background: #494a51;
}

/* */

.styles_ethInputWrapper__2PrOV {
    position: relative;
    width: 60%;
    margin: 0 auto;
}

.styles_ethInputIco__2CVYN {
    position: absolute;
    top: 1px;
    right: 1px;
    padding: 18px 8px;
    z-index: 2;
    width: 96px;
    border-radius: 0 12px 12px 0;
    background: #000000;
}

.styles_ethInputLabel__15HFn {
    font-size: 14px;
    color: #585858;
}

.styles_ethInput__3jmcz {
    position: relative;
    background: #1b1b1b;
    border: 1px solid #8c8c8c;
    color: #fff;
    text-align: right;
    font-size: 38px;
    height: 96px;
    padding: 8px 110px 8px 8px;
    width: 100%;
    border-radius: 12px;
}

.styles_baseInput__3iAnX {
    position: relative;
    background: #1b1b1b;
    border: 1px solid #8c8c8c;
    color: #fff;
    font-size: 18px;
    width: 100%;
    padding: 8px;
    border-radius: 12px;
}

.styles_ethInput__3jmcz.styles_error__1nV9X {
    border-color: #f00;
}

.styles_errorMessage__MG7UE {
    color: #f00;
}

/* GRID */

.styles_flex__3ac7m {
    display: -webkit-flex;
    display: flex;
}

.styles_gridRow__1BXC7 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.styles_gridCalAlignCenter__2FKJb {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_gridRowAlignBaseline__22a0f {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.styles_spaceBetween__1cxXO {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.styles_mbM__uKfC4 {
    margin-bottom: 12px;
}

.styles_mbMl__3SGEu {
    margin-bottom: 16px;
}

.styles_mbL__CTlX6 {
    margin-bottom: 20px;
}

.styles_mbXl__3ttg6 {
    margin-bottom: 24px;
}

.styles_mbXXl__1clbh {
    margin-bottom: 28px;
}

.styles_mbXXXl__39JgW {
    margin-bottom: 32px;
}

.styles_pxXl__12twM {
    padding-left: 24px;
    padding-right: 24px;
}

.styles_gridCal1__2HSPw {
    width: 10%;
}

.styles_gridCal2__3jxED {
    width: 20%;
}

.styles_gridCal25__30VV9 {
    width: 25%;
}

.styles_gridCal3__32pqM {
    width: 30%;
}

.styles_gridCal5__3BnzH {
    width: 50%;
}

.styles_gridCal10__1Br77 {
    width: 100%;
}

.styles_gridBlock__2nwTO {
    display: block;
}

.styles_gridSpace__jkg3e {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

@media screen and (max-height: 800px) {
    .styles_fontSizeXLarge__4mUyj {
        font-size: 38px;
    }
    .styles_fontSizeXMedium__3szor {
        font-size: 20px;
    }
}

@media screen and (max-height: 650px) {
    .styles_fontSizeXLarge__4mUyj {
        font-size: 35px;
    }
    .styles_fontSizeXMedium__3szor {
        font-size: 18px;
    }
}

@media screen and (max-width: 1150px) {
    .styles_fontSizeMedium__QzUDE {
        font-size: 16px;
        font-weight: bold;
    }
}

@media screen and (max-width: 900px) {
    .styles_fontSizeXLarge__4mUyj {
        font-size: 42px;
        font-weight: 500;
    }
}

@media screen and (max-width: 576px) {
    .styles_fontSizeXLarge__4mUyj {
        font-size: 26px;
        font-weight: 500;
    }

    .styles_fontSizeExtraLarge__3Y182 {
        font-size: 36px;
        font-weight: 600;
    }

    .styles_fontSizeLarge__1xVNr {
        font-size: 28px;
        font-weight: 400;
    }

    .styles_fontSizeXMedium__3szor {
        font-size: 18px;
        font-weight: 400;
    }

    .styles_fontSizeMediumNormal__3vXrh {
        font-size: 13px;
        font-weight: 400;
    }
}

@media screen and (max-height: 600px) {
    .styles_fontSizeXLarge__4mUyj {
        font-size: 18px;
        font-weight: 500;
    }
}

@media screen and (max-width: 280px) {
    .styles_fontSizeXLarge__4mUyj {
        font-size: 18px;
        font-weight: 500;
    }
}

.spinner_spinner__1CPhb {
    width: 20px;
    height: 20px;
    background-color: var(--black-color);
    border-radius: 50%;
    border: 2px solid var(--rose-color);
    border-bottom-color: transparent;
    position: relative;
    -webkit-animation: spinner_spinnerAnim__3F5Zl 1s linear infinite;
            animation: spinner_spinnerAnim__3F5Zl 1s linear infinite;
}

@-webkit-keyframes spinner_spinnerAnim__3F5Zl {
    to {
        -webkit-transform: rotate(1turn);
                transform: rotate(1turn);
    }
}

@keyframes spinner_spinnerAnim__3F5Zl {
    to {
        -webkit-transform: rotate(1turn);
                transform: rotate(1turn);
    }
}

.transaction-state_item__2cR4S {
    text-align: left;
    margin-bottom: 12px;
}

.transaction-state_itemSuccess__2-qE3 {
    text-align: center;
}

.transaction-state_itemHead__1ExGp {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.transaction-state_itemHead__1ExGp svg path {
    fill: #b87af7;
}

.transaction-state_itemHeadLabel__sZ95W {
    padding-left: 10px;
}

.transaction-state_itemDescription__295OG {
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    color: #a3a3a3;
    border-left: 1px solid #494a51;
    margin-left: 32px;
    margin-top: 4px;
    padding: 8px 20px;
}

.transaction-state_tnxHash__3mxuc {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.transaction-state_itemStatus__371rg {
    font-size: 14px;
    border-left: 1px solid #494a51;
    margin-left: 32px;
    padding: 8px 20px;
}

.transaction-state_itemStatusLabel__3pSbS {
    padding-right: 24px;
}

.rkl-modal-dialog_container__1bGAh {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.rkl-modal-dialog_containerTopView__1Ttu6 {
    z-index: 9;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-top: 93px; /* header height */
}

.rkl-modal-dialog_dialog__utwL8 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 950px;
    border-radius: 23px;
    height: auto;
    text-align: center;
    background: var(--black-color);
    margin: 0 12px;
    border: 1px solid var(--grey-color);
    position: relative;
    color: var(--white-color);
}

.rkl-modal-dialog_containerTopViewMobile__3hxm4 .rkl-modal-dialog_dialog__utwL8,
.rkl-modal-dialog_containerTopView__1Ttu6 .rkl-modal-dialog_dialog__utwL8 {
    border-radius: 0 0 23px 23px;
}

.rkl-modal-dialog_inner__1ZrCd {
    width: 100%;
    height: 100%;
    padding: 32px 24px;
    overflow: hidden;
}

.rkl-modal-dialog_navigation__hjw-H {
    width: 100%;
    padding: 0 24px;
}

.rkl-modal-dialog_closeModalButton__3pPDC {
    position: absolute;
    right: 15px;
    top: 15px;
}

.rkl-modal-dialog_closeModalButton__3pPDC > svg {
    width: 40px;
    height: 40px;
}

.rkl-modal-dialog_closeModalButton__3pPDC:hover {
    opacity: 0.9;
    cursor: pointer;
}

.rkl-modal-dialog_containerTopViewMobile__3hxm4 {
    z-index: 9;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-top: 93px; /* header height */
}

@media (min-width: 320px) {
    .rkl-modal-dialog_dialog__utwL8 {
        width: 100%;
        padding-top: 40px;
    }
}

.fontTitleLarge {
    font-size: calc((60 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontTitleRegular {
    font-size: calc((48 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontSubtitleLarge {
    font-size: calc((32 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontSubtitleMedium {
    font-size: calc((30 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontSubtitleSmall {
    font-size: calc((20 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontSubtitleXS {
    font-size: calc((18 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontBodyXL {
    font-size: calc((24 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontBodyL {
    font-size: calc((20 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontBodyM {
    font-size: calc((18 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontBodySmallBold {
    font-size: 1rem;
    font-weight: 700;
    line-height: 700;
}

.fontBodySmall {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
}

.fontBodyXSBold {
    font-size: calc((14 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontBodyXS {
    font-size: calc((14 / 16) * 1rem);
    font-weight: 400;
    line-height: 1.2;
}

.buttonFontLarge {
    font-size: calc((20 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.buttonFontXS {
    font-size: calc((14 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.flex-center {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.flex-center-column {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

:root {
    --white-color: #fff;
    --white-color-2: #dddddd;
    --black-color: #000000;
    --darkGrey-color: #1b1b1b;
    --grey-color: #494a50;
    --violet-color: #ae80f0;
    --rose-color: #b87af7;
    --green-color: #b1f9e0;
    --error-color: #b95959;
    --lightGrey-color: #a1a1a1;
    --grey-color-2: #c1c1c1;
    --grey-color-3: #494a51;

    --font-size-20: calc((20 / 16) * 1rem);
    --font-size-60: calc((60 / 16) * 1rem);
    --font-size-48: calc((48 / 16) * 1rem);
    --font-size-32: calc((32 / 16) * 1rem);
    --font-size-30: calc((30 / 16) * 1rem);
    --font-size-24: calc((24 / 16) * 1rem);
    --font-size-20: calc((20 / 16) * 1rem);
    --font-size-18: calc((18 / 16) * 1rem);
    --font-size-16: 1rem;
    --font-size-14: calc((14 / 16) * 1rem);
    --font-size-12: calc((12 / 16) * 1rem);
    --font-size-10: calc((10 / 16) * 1rem);
    --font-size-8: calc((8 / 16) * 1rem);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Inter', sans-serif;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html,
body,
#__next {
    height: 100%;
    font-family: 'Inter', sans-serif;
}

div,
span,
input,
textarea,
select {
    -moz-box-sizing: border-box;
         box-sizing: border-box;
}

body {
    height: 100%;
    width: 100%;
    background-color: #fff;
}

#__next {
    height: 100%;
    width: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

.body-overflow {
    overflow: hidden;
}
:root {
    --white-color: #fff;
    --black-color: #000;
    --gray-color: #a6a6a6;
    --gray-light-color: #c3c3c3;

    --accent-rose-color: #b87af7;
    --accent-yellow-color: #feda5e;

    --transparent-color: transparent;

    --border-radius: 4px;

    --border-thin-width: 1px;
    --border-normal-width: 4px;

    --xsmall-font-size: 0.875rem; /* 14px */
    --small-font-size: 1rem; /* 16px */
    --regular-font-size: 1.125rem; /* 18px */
    --xregular-font-size: 1.25rem; /* 20px */
    --medium-font-size: 1.5rem; /* 24px */
    --xmedium-font-size: 2rem; /* 32px */
    --large-font-size: 2.5rem; /* 40px */
    --xlarge-font-size: 3.75rem; /* 60px */

    --grid1x: 4px;
    --grid2x: 8px;
    --grid3x: 12px;
    --grid4x: 16px;
    --grid5x: 20px;
    --grid6x: 24px;
    --grid7x: 28px;
    --grid8x: 32px;
}

