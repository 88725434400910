:root {
    --white-color: #fff;
    --black-color: #000;
    --gray-color: #a6a6a6;
    --gray-light-color: #c3c3c3;

    --accent-rose-color: #b87af7;
    --accent-yellow-color: #feda5e;

    --transparent-color: transparent;

    --border-radius: 4px;

    --border-thin-width: 1px;
    --border-normal-width: 4px;

    --xsmall-font-size: 0.875rem; /* 14px */
    --small-font-size: 1rem; /* 16px */
    --regular-font-size: 1.125rem; /* 18px */
    --xregular-font-size: 1.25rem; /* 20px */
    --medium-font-size: 1.5rem; /* 24px */
    --xmedium-font-size: 2rem; /* 32px */
    --large-font-size: 2.5rem; /* 40px */
    --xlarge-font-size: 3.75rem; /* 60px */

    --grid1x: 4px;
    --grid2x: 8px;
    --grid3x: 12px;
    --grid4x: 16px;
    --grid5x: 20px;
    --grid6x: 24px;
    --grid7x: 28px;
    --grid8x: 32px;
}
