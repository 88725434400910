.fontTitleLarge {
    font-size: calc((60 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontTitleRegular {
    font-size: calc((48 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontSubtitleLarge {
    font-size: calc((32 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontSubtitleMedium {
    font-size: calc((30 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontSubtitleSmall {
    font-size: calc((20 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontSubtitleXS {
    font-size: calc((18 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontBodyXL {
    font-size: calc((24 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontBodyL {
    font-size: calc((20 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontBodyM {
    font-size: calc((18 / 16) * 1rem);
    font-weight: 500;
    line-height: 1.2;
}

.fontBodySmallBold {
    font-size: 1rem;
    font-weight: 700;
    line-height: 700;
}

.fontBodySmall {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
}

.fontBodyXSBold {
    font-size: calc((14 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.fontBodyXS {
    font-size: calc((14 / 16) * 1rem);
    font-weight: 400;
    line-height: 1.2;
}

.buttonFontLarge {
    font-size: calc((20 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.buttonFontXS {
    font-size: calc((14 / 16) * 1rem);
    font-weight: 700;
    line-height: 1.2;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
